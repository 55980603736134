import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';

import * as Sentry from '@sentry/angular-ivy';

if (environment.production) {
  enableProdMode();
}

if (!window.location.href.includes('localhost')) {
  const sampleRate = 0.2;

  Sentry.init({
    dsn: 'https://e96766cc5fe1209658e9be96bbf9c1d0@o4504603852144640.ingest.sentry.io/4505708026331136',
    sendClientReports: false,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false
      }),
    ],
    tracesSampleRate: sampleRate,
    tracePropagationTargets: [/^https\:\/\/admin\.mediaspirit\.io/, /^https\:\/\/new-admin\.mediaspirit\.io/],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.5,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
